<template>
  <div
    style="
    padding: 15px;"
  >
    <v-card class="px-3 py-2">
      <v-row>
        <v-col
          cols="12"
          class="d-flex"
          style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
        >
          <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
            REALISASI QUANTITAS
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h6>
        </v-col>
        <v-col cols="12">
          <v-toolbar flat style="height: 60px;">
            <div>
              <v-select
                v-model="paramApi.vehicle_type"
                :items="[]"
                @change="vehicleTypeWatcher"
                label="Jenis Kendaraan"
                style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                      margin-left:auto;
                    "
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
              ></v-select>
            </div>
            <div style="margin-left:15px;">
              <v-select
                v-model="paramApi.period"
                :items="[2021, 2022, 2023]"
                @change="periodWatcher"
                label="Periode"
                style="
                      position: relative;
                      top: 15px;
                      font-size: 12px;
                      margin-left:auto;
                    "
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
              ></v-select>
            </div>
            <v-btn
              outlined
              elevation="1"
              color="indigo"
              class="indigo--text font-weight-bold"
              style="font-size:12px;margin-top:5px;margin-left:auto;"
              :disabled="loading"
            >
              Download
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col cols="12" style="padding:20px;">
          <v-row no-gutters>
            <v-expansion-panels accordion>
              <v-expansion-panel v-for="(item, i) in 5" :key="i">
                <v-expansion-panel-header>Item</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
// import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'qtyrealization',
  components: {},
  data: () => ({
    expedition: buildType.apiURL('expedition'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    loading: false,
    totalData: 0,
    result: [],
    paramApi: {
      period: 2023,
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      page: 1,
      orderBy: 'name',
      orderType: 'asc'
    },

    headers: [
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Jarak (km)',
        value: 'distance',
        align: 'left',
        sortable: false
      },
      {
        text: 'Shema',
        value: 'price_scheme',
        align: 'left',
        sortable: false
      }
    ],
    enableWatcher: true
  }),
  async mounted() {
    this.getDataFromApi()
  },
  methods: {
    async periodWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    async vehicleTypeWatcher(v) {
      // this.paramApi.company_id = v
      if (this.enableWatcher) {
        setTimeout(async () => {
          await this.getDataFromApi()
        }, 100)
      }
    },
    refreshPage() {
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.result = []
      this.loading = true
      await this.initDataTable().then(data => {
        setTimeout(() => {
          this.result = data.data
          this.totalData = data.total_record
          this.loading = false
        }, 1000)
      })
    },
    async initDataTable() {
      var url = `${this.expedition}report/realisasi_qty?year=${this.paramApi.period}`

      return await new Promise(resolve => {
        axios
          .get(url)
          .then(res => {
            console.log(res)
            resolve(res.data)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async updatePage(p) {
      this.paramApi.page = p
      this.paramApi.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramApi.limit = p
      this.paramApi.offset = 0
      await this.getDataFromApi()
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
